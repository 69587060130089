<template>
  <div>
    <!--- Navigator-->
    <b-row>
      <b-col cols="3">
        <h3><b>จัดการลูกค้า</b></h3>
        <small>
              <span class="text-primary" style="cursor: pointer;" @click="$router.push('/setting/customer/warranty/sub-company')"><b>จัดการลูกค้า</b> ></span>                         
              <span class="text-primary"  @click="$router.push(`/setting/customer/warranty/branch?scid=${$route.query.scid}&subCompanyName=${$route.query.subCompanyName}`)" style="cursor: pointer;"><b> {{$route.query.subCompanyName}}</b> > </span>                                                                          
          <b>สาขา</b>
        </small>
      </b-col>
      <b-col class="d-flex justify-content-end align-items-center">
        <b-button
          v-if="customerPermission=='create' || customerPermission=='edit'"          
          variant="primary"
          size="sm"
          style="height: 35px"
          class="mr-1"
          @click="save"          
        >
          <feather-icon icon="SaveIcon" />
          บันทึก
        </b-button>

        <b-button
          variant="outline-dark"
          size="sm"
          @click="$router.push(`/setting/customer/warranty/branch?scid=${$route.query.scid}&subCompanyName=${$route.query.subCompanyName}`)"
          style="height: 35px"
        >
          <feather-icon icon="ArrowLeftIcon" />
          ย้อนกลับ
        </b-button>
      </b-col>
    </b-row>
    <!--- Navigator-->

    <b-row class="mt-1">
      <b-col cols="12">
        <!-- START SECTION FORM วันเดินทาง--->
        <b-card>
          <b-row>
            <b-col
              ><h2>
                <b v-if="$route.query.bid">แก้ไขสาขา</b>
                <b v-else>เพิ่มสาขา</b>
              </h2></b-col
            >
          </b-row>
          <!--- START (ชื่อสาขา) --->
          <b-row class="mt-2">
            <b-col md="3" class="">
              <label>รหัสสาขา <span class="text-danger">*</span></label>
              <b-form-input
                placeholder="Vserv"
                size="sm"
                value="Vserv"
                v-model="branchData.branchCode"
              />
            </b-col>

            <b-col class="">
              <label>ชื่อสาขา <span class="text-danger">*</span></label>
              <b-form-input
                placeholder="Vserv"
                size="sm"
                value="Vserv"
                v-model="branchData.branchName"
              />
            </b-col>

            <b-col class="">
              <label>ชื่อผู้ติดต่อ</label>
              <b-form-input
                placeholder="คุณกรณ์"
                size="sm"               
                v-model="branchData.contactName"
              />
            </b-col>
          </b-row>
          <!--- END (ชื่อสาขา) --->

          <!--- START (เบอร์โทร) --->
          <b-row class="mt-1">
            <b-col md="3" class="">
              <label>เบอร์โทร</label>
              <b-form-input
                placeholder="0987654321"
                size="sm"
                value="0987654321"
                v-model="branchData.tel"
              />
            </b-col>

            <b-col md="3" class="">
              <label>Email</label>
              <b-form-input
                placeholder="admin@gmail.com"
                size="sm"
                value="admin@gmail.com"
                v-model="branchData.email"
              />
            </b-col>
            <b-col md="2" class="">
              <label>ศูนย์บริการ</label>
              <v-select
                id="brand"
                v-model="zoneSelected"
                label="title"
                :options="zoneOption"
                :clearable="false"
                class="select-size-sm"  
                @input="onChangeZone"           
              />
            </b-col>

            <b-col md="2" class="">
              <label>จังหวัด</label>
              <v-select
                id="brand"                
                v-model="provinceSelected"                
                label="province_name"
                :options="provinceOption"
                :clearable="false"
                class="select-size-sm"                
              />             
            </b-col>

            <b-col md="2" class="">
              <label>เขต</label>
              <v-select
                id="brand"                
                v-model="countrySelected"                
                label="name"
                :options="countryList"
                :clearable="false"
                class="select-size-sm"        
                :disabled="provinceSelected.id!==2"        
              />
            </b-col>
            
          </b-row>
          <!--- END (เบอร์โทร) --->

          <!--- START ROW ที่อยู่ --->
          <b-row class="mt-1">
            <b-col md="6">
              <label>ที่อยู่</label>
              <b-form-textarea
                id="textarea-default"
                placeholder="ที่อยู่"
                rows="2"
                size="sm"
                v-model="branchData.address"
              />
            </b-col>

            <b-col md="6">
              <label>หมายเหตุ</label>
              <b-form-textarea
                id="textarea-default"
                placeholder="หมายเหตุ"
                rows="2"
                size="sm"
                v-model="branchData.remark"
              />
            </b-col>
          </b-row>
          <!--- END ที่อยู่ --->

          <!--- START (เบอร์โทร) --->
          <b-row class="mt-1">
            <b-col md="3" class="">
              <label>Latitude</label>
              <b-form-input
                placeholder="1.0002292"
                size="sm"
                value="1.0002292"
                v-model="branchData.latitude"
              />
            </b-col>

            <b-col md="3" class="">
              <label>Longtitude</label>
              <b-form-input
                placeholder="1.223444"
                size="sm"
                value="1.223444"
                v-model="branchData.longtitude"
              />
            </b-col>
            <b-col md="2" class="">
              <label>สถานะ</label>
              <b-form-group>
                <b-form-radio-group
                  :options="statusOptions"
                  name="radio-inline"
                  v-model="branchData.status"
                />
              </b-form-group>
            </b-col>
          </b-row>
          <!--- END (เบอร์โทร) --->
        </b-card>
        <!-- END SECTION FORM วันเดินทาง--->
      </b-col>
    </b-row>
  </div>
</template>

<script>
import {
  BRow,
  BCol,
  BCard,
  BFormGroup,
  BButton,
  BFormCheckbox,
  BInputGroupPrepend,
  BInputGroupAppend,
  BInputGroup,
  BFormInput,
  BFormTextarea,
  BFormRadioGroup,
} from "bootstrap-vue";
import vSelect from "vue-select";

import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";

//SERVICES
import CustomerService from "@/services/setting/customer";
import OtherService from "@/services/other";

export default {
  components: {
    BRow,
    BCol,
    BCard,
    BFormGroup,
    BButton,
    BFormInput,
    BInputGroup,
    BFormCheckbox,
    BFormTextarea,
    BInputGroupPrepend,
    BInputGroupAppend,
    BFormRadioGroup,
    vSelect,
  },
  data() {
    return {
      statusSelected: 1,
      statusOptions: [
        { text: "เปิด", value: 1 },
        { text: "ปิด", value: 0 },
      ],
      zoneSelected: { title: "ภาคกลาง" , value:"c" },
      zoneOption: [
        { title: "ภาคกลาง" , value:"c"},
        { title: "ภาคกลางตอน(บน)" , value:"cn"},
        { title: "ภาคตะวันออกเฉียงเหนือ(บน)", value:"en" },
        { title: "ภาคตะวันออกเฉียงเหนือ(ล่าง)", value:"es" },
        { title: "ภาคเหนือ" , value:"n"},
        { title: "ภาคใต้" , value:"s"},
        { title: "ภาคตะวันออก" , value:"e"},
        { title: "ภาคตะวันตก" , value:"w"},
      ],
      provinceSelected:{},
      provinceOption: [],   
      provinceInit: [],   
      branchData: {
        branchName: "",
        branchCode: "",
        tel: "",
        email: "",
        zoneId: 0,
        provinceId: 0,
        address: "",
        remark: "",
        latitude: 0,
        longtitude: 0,
        gasRate: 0,
        status: 1,
        mode:"warranty"
      },
      countrySelected:{id:0, name:"-"},
      countryList:[]
    };
  },

  computed: {
    customerPermission () {
      const permission = this.$store.getters["user/getPermissionPage"]("ST003-1")
      if(permission=="disable"){
        localStorage.removeItem("userData")
        window.location.href="/not-authorized"
      }
      return permission
    },
    mode() {
      const branchId = this.$route.query.bid;
      if (branchId) {
        return "edit";
      }
      return "create";
    },
    paramBranchId() {
      return this.$route.query.bid;
    },
    paramSubCompanyId() {
      return this.$route.query.scid;
    },  
  },

  watch:{
    provinceSelected(){
      //NOT BANGKOK
      if(this.provinceSelected.id !==2){
        this.countrySelected = {id:0, name:"-"}
      }
    }
  },

  methods: {
    async save() {      
      if(!this.branchData.branchCode){
        alert("กรุณากรอกรหัสสาขา")
        return
      }

      if(!this.branchData.branchName){
        alert("กรุณากรอกชื่อสาขา")
        return
      }


      if (this.mode == "create") {
        await this.insert();
      } else {
        await this.update();
      }
    },

    async getCustomerBranchDetail() {
      const result = await CustomerService.GetCustomerBranchById(
        this.paramBranchId
      );
      this.branchData = result.data;
      this.restoreProvince()
      this.restoreContry()
      
    },
    async getCountry() {
      const result = await CustomerService.GetCountry();      
      this.countryList = result.data.data;    
    },
    async insert() {
      this.isLoading = true;
      const result = await CustomerService.InsertCustomerBranch({
        ...this.branchData,
        subCompanyId: this.paramSubCompanyId,
        zoneId:this.zoneSelected.value || 0,
        provinceId:this.provinceSelected.id || 0,
        countryId:this.countrySelected.id || 0
      });
      if (result.status == "success") {
        if(result.data.status == "dupplicate"){
          this.$toast({
            component: ToastificationContent,
            position: "top-right",
            props: {
              title: `Customer Branch`,
              icon: "XIcon",
              variant: "danger",
              text: `ชื่อนี้มีการใช้งานแล้ว กรุณาเปลี่ยนชื่อสาขา`,
            },
          });
        }else{
          this.$toast({
          component: ToastificationContent,
          position: "top-right",
          props: {
            title: `Customer Branch`,
            icon: "CheckIcon",
            variant: "success",
            text: `เพิ่มรายการเรียบร้อย`,
          },
        });
        this.$router.push(
          `/setting/customer/warranty/branch?scid=${this.$route.query.scid}&subCompanyName=${this.$route.query.subCompanyName}`
        );
        }
       

        
       
      } else {
        this.$toast({
          component: ToastificationContent,
          position: "top-right",
          props: {
            title: `Customer Branch`,
            icon: "XIcon",
            variant: "danger",
            text: `พบปัญหาในการเพิ่มรายการ`,
          },
        });
      }

      this.isLoading = false;
    },
    async update() {
      this.isLoading = true;
      console.log("zone", this.zoneSelected)
      const obj={
          ...this.branchData,
          zoneId:this.zoneSelected.value || 0,
          provinceId:this.provinceSelected.id || 0,
          countryId:this.countrySelected.id || 0
      }

      const result = await CustomerService.UpdateCustomerBranch(obj,this.paramBranchId);
      if (result.status == "success") {
        this.$toast({
          component: ToastificationContent,
          position: "top-right",
          props: {
            title: `Customer Branch`,
            icon: "CheckIcon",
            variant: "success",
            text: `แก้ไขรายการเรียบร้อย`,
          },
        });
        this.$router.push(
          `/setting/customer/warranty/branch?scid=${this.$route.query.scid}&subCompanyName=${this.$route.query.subCompanyName}`
        );
      } else {
        this.$toast({
          component: ToastificationContent,
          position: "top-right",
          props: {
            title: `Customer Branch`,
            icon: "XIcon",
            variant: "danger",
            text: `พบปัญหาในการแก้ไขรายการ`,
          },
        });
      }
      this.isLoading = false;
    },

    async getProvinceList() {
      const result = await OtherService.GetProvinceList();
      this.provinceOption = result.data;
      this.provinceInit = result.data;
      
      if (result.data.length > 0) {
        //this.provinceSelected = result.data[1];
      }
    },

    onChangeZone(item){   
      const provinces = [...this.provinceInit]
      const provinceSelected = provinces.filter(f=>f.provice_phase==item.value)   
      this.provinceOption = provinceSelected
      this.provinceSelected = provinceSelected[0]      
    },
    restoreProvince(){
      const provinces = [...this.provinceInit]
      const provinceSelected = provinces.find(f=>f.id==this.branchData.provinceId)   
      this.provinceSelected = provinceSelected || provinces[0]

      const zones = [...this.zoneOption]
      const zoneSelected = zones.find(f=>f.value==this.branchData.zoneId)  
     
      this.zoneSelected = zoneSelected || zones[0]
    },
    restoreContry(){
      const countryList = [...this.countryList]
      const countrySelected = countryList.find(f=>f.id==this.branchData.countryId)   
      this.countrySelected = countrySelected || countryList[0]
    }
  },

  async mounted() {
    await this.getProvinceList();
    await this.getCountry()
    if (this.mode == "edit") {
      await this.getCustomerBranchDetail();      
    }else{
      this.onChangeZone({ title: "ภาคกลาง" , value:"c" })
    }
  },
};
</script>
<style scoped>
.table-responsive {
  overflow: none !important;
}
</style>
